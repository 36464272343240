import React, { useState, useRef, useEffect } from 'react';

import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
import PlayButton from '../Assets/Images/play-green.png';
import Clock from '../Assets/Images/clock.png';
import { Close } from "@mui/icons-material";

import useWindowDimenions from "../Hooks/useWindowDimensions";

import '../App.scss';

const Start = props => {

  const { windowWidth } = useWindowDimenions();

  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  
  const vidRef                            = useRef(null);
  
  searchParams.get("leader");

  useEffect(() => { 
		document.body.style.backgroundColor = "#EEEEEE";
	},[]);

  const assessmentLink = () => {
    if(searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  // const playVideo = (e) => {
  //   console.log(vidRef.current);
  //   vidRef.current.play();
  //   setPlayingVideo(true);
  //   if(!startPlaying) {
  //     setStartPlaying(true);
  //   }
  // }

  // const pauseVideo = (e) => {
  //   console.log(vidRef.current);
  //   vidRef.current.pause();
  //   setPlayingVideo(false);
  // }

  const playVideo = (e) => {
    // console.log(vidRef.current);
    // vidRef.current.play();
    // setPlayingVideo(true);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.width = "0%";
      // document.getElementsByClassName("intro")[0].style.padding = "0";
      // document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("video")[0].style.width = "100%";
      // document.getElementsByClassName("video")[0].style.margin = "0 auto";
      // document.getElementsByClassName("video")[0].style.backgroundColor = "#E5E5E5";
      // document.getElementsByClassName("video")[0].style.borderRadius = "0";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    // console.log(vidRef.current);
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.width = "46%";
      // document.getElementsByClassName("intro")[0].style.padding = "0 2%";
      // document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("video")[0].style.width = "50%";
      // document.getElementsByClassName("video")[0].style.margin = "0 auto";
      // document.getElementsByClassName("video")[0].style.backgroundColor = "#E5E5E5";
      // document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      // document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    setStartPlaying(false);
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.width = "46%";
      // document.getElementsByClassName("intro")[0].style.padding = "0 2%";
      // document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("video")[0].style.width = "50%";
      // document.getElementsByClassName("video")[0].style.margin = "0 auto";
      // document.getElementsByClassName("video")[0].style.backgroundColor = "#E5E5E5";
      // document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      // document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img alt="thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    }
    // if you want to see the thumbnail image when paused instead of video 
    // else return <img alt="thumbnail" className="thumbnail" src={VideoThumbnail} />
    else {
      if(startPlaying) {
        return null
      } else {
        return <>
          <img alt="thumbnail" className="thumbnail" src={VideoThumbnail} />
          {/* <div className="caption">
            <p className="name">Brad Leeper</p>
            <p>Executive Vice President</p>
            <p>Generis</p>
          </div> */}
        </>
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} 
      onClick={(e) => playVideo(e)} 
      />
    }
  }

  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/Your-Giving-Growth-Intro-Video-v2.mp4";

  return (
    <div className="container start">
      <div className="video">
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source
            src={vidsrcURL}
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()}
        {playingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#dddddddd", opacity: 0.75, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer", 
        right: windowWidth > 1100 ? "70px" : "5px" }} onClick={(e) => pauseVideo(e)} />}
      </div>
      <div className="intro">
        <h1>How aligned is your leadership team around a plan to grow financial giving?</h1>
        {/* <h2>
          Unlock scores in 4 distinct areas of alignment in the leadership team of your Board Members + Head of School. Identify the key elements that may be blocking you from successfully funding your strategic vision.
        </h2> */}
        <ol className="intro-points-list">
          {searchParams.get("leader") ? <>
          <li>Take this 3-minute assessment</li>
          <li>View your results instantly</li>
          <li>Get expert feedback to chart your course forward</li>
          </> : <>
          <li>Take this 3-minute assessment</li>
          <li>Invite your team for increased input</li>
          <li>View the results on a customized dashboard</li>
          <li>Get expert feedback to chart your course forward</li>
          </>
          }
        </ol>
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>3 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;
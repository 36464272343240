import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link, useParams } from "react-router-dom";
import Tabs from './Tabs';
import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

// import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UrgencyScore from '../Components/UrgencyScore';
import useWindowDimensions from '../Hooks/useWindowDimensions';

import scheduledImage from "../Assets/Images/schedule-image.png";

import '../App.scss';

const Dashboard = (props) => {
  
	const { id }                        			= useParams();

	const [isLoading, setIsLoading]   				= useState(true);
	const [currentView, setCurrentView] 			= useState(0);

  const [toggleIndividual, setToggleIndividual]		= useState(0);
  const [toggleSection, setToggleSection]		= useState(1);
	const [toggleMore, setToggleMore] 				= useState(0);	

	const [members, setMembers]        				= useState([]);
	const [selected, setSelected] 						= useState(0);
	const [questions, setQuestions]   				= useState([]);
	const [sections, setSections]   					= useState([]);
	const [hurdles, setHurdles]        				= useState([]);
	const [enablers, setEnablers]     				= useState([]);
	const [tops, setTops]     								= useState([]);
	const [bottoms, setBottoms]     					= useState([]);
	
	const [score, setScore]             			= useState(0);
  const [subScore1, setSubScore1]     			= useState(0);
  const [subScore2, setSubScore2]     			= useState(0);
  const [subScore3, setSubScore3]     			= useState(0);
  const [subScore4, setSubScore4]     			= useState(0);
  const [subScore5, setSubScore5]     			= useState(0);
  const [subScore6, setSubScore6]     			= useState(0);
  const [subScore7, setSubScore7]     			= useState(0);
  const [subScore8, setSubScore8]     			= useState(0);
  const [urgencyVote1, setUrgencyVote1]			= useState(0);
  const [urgencyVote2, setUrgencyVote2]			= useState(0);
  const [urgencyVote3, setUrgencyVote3]			= useState(0);
  const [urgencyVote4, setUrgencyVote4]			= useState(0);
  const [urgencyVote5, setUrgencyVote5]			= useState(0);
  const [urgencyScore, setUrgencyScore]			= useState(0);
	const [memberSection1, setMemberSection1] = useState([]);
	const [memberSection2, setMemberSection2] = useState([]);
	const [memberSection3, setMemberSection3] = useState([]);
	const [memberSection4, setMemberSection4] = useState([]);
	const [memberSection5, setMemberSection5] = useState([]);

	const [memberScore, setMemberScore] 			= useState(0);
	const [memberName, setMemberName] 				= useState(0);
  const [memSubScore1, setMemSubScore1]			= useState(0);
  const [memSubScore2, setMemSubScore2]			= useState(0);
  const [memSubScore3, setMemSubScore3]			= useState(0);
  const [memSubScore4, setMemSubScore4]			= useState(0);
  const [memSubScore5, setMemSubScore5]			= useState(0);
  const [memSubScore6, setMemSubScore6]			= useState(0);
  const [memSubScore7, setMemSubScore7]			= useState(0);
  const [memSubScore8, setMemSubScore8]			= useState(0);
  const [memUrgencyScore, setMemUrgencyScore]	= useState(0);

	const { windowWidth } = useWindowDimensions();

	const scores = [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8];
	const memScores = [memSubScore1, memSubScore2, memSubScore3, memSubScore4, memSubScore5, memSubScore6, memSubScore7, memSubScore8];
	
	let urgencyPercent 			= 60.0;
	if(window.innerWidth > 650) {
		urgencyPercent 				= 63.9;
	}

	useEffect(() => {
		document.body.style.backgroundColor = "#007670";
    fetchResults(); 
  }, []);

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
				setSubScore5(data.subScore5);
				setUrgencyScore(data.urgencyScore);
				setMembers(data.assessmentInfo);
				setQuestions(data.questionInfo);
				setSections(data.sectionInfo);
				setHurdles(data.hurdles);
				setEnablers(data.enablers);
				
				// console.log(data.questionInfo);

				setSelected(data.assessmentInfo[0].id);
				setMemberScore(data.assessmentInfo[0].score);
				setMemberName(data.assessmentInfo[0].fullName);
				setMemSubScore1(data.assessmentInfo[0].subScore1);
				setMemSubScore2(data.assessmentInfo[0].subScore2);
				setMemSubScore3(data.assessmentInfo[0].subScore3);
				setMemSubScore4(data.assessmentInfo[0].subScore4);
				setMemSubScore5(data.assessmentInfo[0].subScore5);
				setMemUrgencyScore(data.assessmentInfo[0].addAnswer1);

				let urgencyVote1  = 0;
				let urgencyVote2  = 0;
				let urgencyVote3  = 0;
				let urgencyVote4  = 0;
				let urgencyVote5  = 0;

				let section1Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 1; });
				section1Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection1(section1Questions);
				
				let section2Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 2; });
				section2Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection2(section2Questions);

				let section3Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 3; });
				section3Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection3(section3Questions);
				
				let section4Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 4; });
				section4Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection4(section4Questions);
				
				let section5Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 5; });
				section5Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection5(section5Questions);
				
				let tops 					= data.questionTop.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
				let bottoms 			= data.questionBottom.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));

				let urgVote1  		= 0;
				let urgVote2  		= 0;
				let urgVote3  		= 0;
				let urgVote4  		= 0;
				let urgVote5  		= 0;

				data.assessmentInfo.forEach((member) => {
					if(member.addAnswer1 == 1) {
						urgVote1++;
					} else if(member.addAnswer1 == 2) {
						urgVote2++;
					} else if(member.addAnswer1 == 3) {
						urgVote3++;
					} else if(member.addAnswer1 == 4) {
						urgVote4++;
					} else if(member.addAnswer1 == 5) {
						urgVote5++;
					}
				});

				setUrgencyVote1(urgVote1);
				setUrgencyVote2(urgVote2);
				setUrgencyVote3(urgVote3);
				setUrgencyVote4(urgVote4);
				setUrgencyVote5(urgVote5);

				// setTops(tops.slice(0, 3));
				// setBottoms(bottoms.slice(0, 3));
				setTops(data.questionTopV3.slice(0, 3));
				setBottoms(data.questionBottomV3.slice(0, 3));
				setIsLoading(false);

				// console.log(tops);
				// console.log(bottoms);
				// console.log(questions);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

	const urgencyComponentProps = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5 };

	const showTitleText = (scoreVal) => {
    if (scoreVal > 80) {
      return <h1><span className="user-name">{memberName}</span>, it looks like you're doing a great job at stewardship and giving. Way to go!</h1>
		} else if (scoreVal > 50 && scoreVal <= 80) {
      return <h1><span className="user-name">{memberName}</span>, for churches like yours that are stable, it is easier to move to increased financial giving from this core foundation.</h1>
    } else if (score >= 0 && score <= 50) {
      return <h1><span className="user-name">{memberName}</span>, it looks like you might be facing challenges in your giving.</h1>
    }
  };

	const showScoreText = (scoreVal) => {
    if (scoreVal > 80) {
      return <div className="result-text">
        <p>Congratulations! Based on your score, it looks like you're doing a great job at growing giving.</p>
        <p>Because you have momentum and apparent health now, it is more likely that you can increase giving even more as people are engaged and grow in stewardship. What if you could increase your budget by 10%? 25%? Your current momentum gives you more options to expand financial resources for your mission and ministry. How might you build upon this strong foundation?</p>
      </div>
    } else if (scoreVal > 50 && scoreVal <= 80) {
      return <div className="result-text">
        <p>Based on your score, it looks like there's a strong potential to grow your giving more than you might think.</p>
        <p>While this can seem like a challenge initially, focusing on the 5 assessment areas can help you cast a clear vision for generosity and strengthen your giving strategy as you work toward building a culture of biblical stewardship in your church.</p>
      </div>
    } else if (scoreVal >= 0 && scoreVal <= 50) {
      return <div className="result-text">
        <p>Based on your score, it looks like you might be facing headwinds in your giving, perhaps on a downward trend or missing connection with givers who might want to give more.</p>
        <p>Most often, these areas can be changed with focused attention with increased giving as a result. Most churches can increase financial giving.</p>
        <p>By focusing on the 5 critical areas of this assessment, you can begin to strengthen your approach to biblical stewardship and align around a plan to cultivate a culture of generosity in your church. Let's explore, {memberName}, how your giving can increase. </p>
      </div>
    }
  };


	const toggleTeamIndividual = (tab) => {
		if(tab == 1) {
			setCurrentView(0);
			setToggleIndividual(1);
		} else {
			setCurrentView(1);
			setToggleIndividual(0);
			changeIndividualAssessment(members[0].id)
		}
	}

	const updateIndividualAssessment = (e) => {
		let memberId 			= e.target.value;
		changeIndividualAssessment(memberId);
	}

	const changeIndividualAssessment = (id) => {
		let member 				= members.find(function (obj) { return obj.id == id; });
		console.log(member);
		console.log(questions);
		let sectionIds 		= [];
		sections.forEach((section) => {
			sectionIds.push(section.id);
		});
		console.log(sectionIds);
		setSelected(member.id);
		setMemberScore(member.score);
		setMemberName(member.fullName);
		setMemSubScore1(member.subScore1);
		setMemSubScore2(member.subScore2);
		setMemSubScore3(member.subScore3);
		setMemSubScore4(member.subScore4);
		setMemSubScore5(member.subScore5);
		setMemUrgencyScore(member.addAnswer1);
		
		let section1Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[0]; });
		section1Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});

		setMemberSection1(section1Questions);
		
		let section2Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[1]; });
		section2Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection2(section2Questions);

		let section3Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[2]; });
		section3Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection3(section3Questions);
		
		let section4Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[3]; });
		section4Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection4(section4Questions);
		
		let section5Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[4]; });
		section5Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection5(section5Questions);

	}

	const showAnswerText = (answer, questionId) => {

		let question			= questions.find(function (obj) { return obj.id == questionId; });
		let answerType 		= "green";

		if(question.answers.length == 2) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "green"; }
		} else if(question.answers.length == 3) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "orange"; }
			else if(answer == 3) { answerType = "green"; }
		} else if(question.answers.length == 4) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "green"; }
			else if(answer == 4) { answerType = "green"; }
		} else if(question.answers.length == 5) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "orange"; }
			else if(answer == 4) { answerType = "green"; }
			else if(answer == 5) { answerType = "green"; }
		}
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		
		if(answerVal && answerVal.desktop_text) {
			if(answerType == "red") {
				return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "orange") {
				return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "green") {
				return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			}
		}

	}

	const toggleSectionDetails = (id) => {
		if(id == toggleSection) {
			setToggleSection(0);
		} else {
			setToggleSection(id);
		}
	}

	const toggleChange = (e) => {
		setToggleSection(!e.target.checked);
		if(e.target.checked) {
			toggleTeamIndividual(0);
		} else {
			toggleTeamIndividual(1)
		}
	}

	const toggleIndividualChange = (e) => {
		// setToggleSection(!e.target.checked);
		if(e.target.checked) {
			toggleTeamIndividual(0);
		} else {
			toggleTeamIndividual(1)
		}
	}

	const toggleSectionDisplay = (id) => {
		if(id == toggleSection) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleSectionIcon = (id) => {
		if(id == toggleSection) {
			return <RemoveIcon />
		} else {
			return <ExpandMoreIcon />
		}
	}

	const toggleDetails = (id) => {
		if(id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if(id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if(id == toggleMore) {
			return <RemoveIcon />
		} else {
			return <ExpandMoreIcon />
		}
	}

	const getLeftMargin = (scoreValue) => {
    if (window.innerWidth < 800 && scoreValue > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && scoreValue < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: scoreValue + "%" };
    }
  };

  const getBarTextLeft = (scoreValue) => {
    if (window.innerWidth < 650 && scoreValue > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

	const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

	const selectedMember = members?.find((mem) => mem.id === selected);
	const teamAnswers = [];
	const teamEnablers = [];
	const teamHurdles = [];
	const selectedMembersEnablers = selectedMember?.answers?.find(a => a.question_id == 567)?.answer_txt || "-";
	const selectedMembersHurdles = selectedMember?.answers?.find(a => a.question_id == 568)?.answer_txt || "-";

  members?.forEach((member) => {
		let question1 = member.answers?.find(a => a.question_id == 567);
		let question2 = member.answers?.find(a => a.question_id == 568);
		// if (question1?.answer_txt) teamAnswers.push(question1?.answer_txt);
		if (question1?.answer_txt) teamEnablers.push(question1?.answer_txt);
		if (question2?.answer_txt) teamHurdles.push(question2?.answer_txt);
	})
	console.log("teamAnswers", teamAnswers);
	console.log("selected", selected);
	console.log("selectedMember", selectedMember);
	console.log("members", members);
	console.log("selectedMembersEnablers", selectedMembersEnablers);
	console.log("selectedMembersHurdles", selectedMembersHurdles);

	const showSchedule = () => {
    return (
			<div className="schedule-container">
				<div className="avatar">
					<img src={scheduledImage} />
				</div>
				<h3>Schedule a call with Kim</h3>
				<p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
				<p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
				<div className="cta-btn">
					<a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
						<Button>TALK TO KIM</Button>
					</a>
				</div>
			</div>
		);
  };

  if (isLoading) {
		return <div className="container dashboard loading"><CircularProgress  style={{ color: '#007670' }} size={60} /></div>
	}

	if(currentView == 1) {
		return (
			<div>
				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="toggle-individual-group">
					<div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
					<Switch
						checked={!toggleIndividual}
						onChange={toggleIndividualChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
				</div>

				<div className="container results">

					<div style={{ height: '20px' }}></div>
					<div className="selection-form">
						<div className="form-detail">
							<TextField select hiddenLabel value={selected} onChange={(e) => {updateIndividualAssessment(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
								{members.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.fullName}'s
									</MenuItem>
								))}
							</TextField>
						</div>
						<div className="form-text">assessment score</div>
					</div>

					<div className="details-container">

						<div className="progress-bar">
							<div className="si_ass_result">
								<div className="si_ass_progress">
									<div className="progress-line" style={{ left: `${memberScore}%` }}>
										{/* <div className="progress-text" style={getBarTextLeft(memberScore)}>{showProgressBarText(memberScore)}</div> */}
									</div>
									<span className="msi_score" style={getLeftMargin(memberScore)}>{memberScore}%</span>
								</div>
								
								<div className="pervalue">
									<h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
									{/* <h4 className="nimp" style={{ minWidth: '20%' }}></h4> */}
									<h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
									{/* <h4 className="eexpe" style={{ minWidth: '20%' }}></h4> */}
									<h4 className="eexpe1" style={{ minWidth: '30%' }}></h4>
									<h4 className="eexpe2" style={{ minWidth: '20%' }}></h4>
									{/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
								</div>
								
								
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '50%' }}></h4>
									{/* <h4 className="mexpe" style={{ minWidth: '20%' }}></h4> */}
									{/* <h4 className="eexpe" style={{ minWidth: '20%' }}></h4> */}
									<h4 className="eexpe1" style={{ minWidth: '30%' }}></h4>
									{/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
								</div>
								
								<div className="pervalue-text">
									{/* <p className="not-aligned" style={{ marginLeft: '0%', fontWeight: score <= 45 ? 800 : 600 }}>Not<br />Aligned</p> */}
									<p className="poor-aligned" style={{ width: '50%', fontWeight: score > 0 && score <= 50 ? 800 : 600 }}>Needs Attention</p>
									{/* <p className="some-aligned" style={{ marginLeft: '43%', fontWeight: score > 40 && score <= 60 ? 800 : 600 }}>Somewhat<br />Aligned</p> */}
									<p className="well-aligned" style={{ width: '30%', fontWeight: score > 50 && score <= 80 ? 800 : 600 }}>Adequate</p>
									<p className="high-aligned" style={{ width: '20%', fontWeight: score > 80 ? 800 : 600 }}>Excellent</p>
								</div>

							</div>
						</div>

						<div className="spacer" style={{ height: "15px" }}></div>
						{showScoreText(selectedMember?.score)}

						{/* <div className="spacer"></div> */}
						{windowWidth < 650 && <div className="spacer"></div>}

						<div className="score-section-container">

							{
								sections.map((data, index) => (
									<div key={index} className="score-section">
										<p className="score-txt">{memScores[index]}%</p>
										<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memScores[index]} /></div>
										<p className="score-name">{ data.section }</p>
									</div>)
							)}

						</div>

						{
							sections.map((data, index) => {
								
								let memberSection 		= [];
								if(index == 0) { 
									memberSection1.forEach((member) => {
										console.log(member);
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});									
								} else if(index == 1) {
									memberSection2.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 2) {
									memberSection3.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 3) {
									memberSection4.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index == 4) {
									memberSection5.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								}
								console.log(memberSection);

								return <div key={index} className="members-answered">
									<p className="section" onClick={() => toggleSectionDetails(index + 1)}>
										{data.section}
										{/* <a className="section-toggle" onClick={() => toggleSectionDetails(index + 1)}> */}
										<a className="section-toggle">
											{ toggleSectionIcon(index + 1) }
										</a>
									</p>
									<div style={ toggleSectionDisplay(index + 1) }>
										{
											memberSection.map((data, index) => (
												<div key={index} className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer, data.id)}</div>
											)
										)}
									</div>
								</div>
							}
						)}

						<div className="hurdles-enablers">
							<h2 style={{ }}>BARRIERS TO GIVING</h2>
							<div className="hurdles">
								<ul><li>{selectedMembersHurdles}</li></ul>
							</div>
						</div>
	
						<div className="hurdles-enablers">
							<h2 style={{ }}>$1M GIFT INVESTMENT </h2>
							<div className="enablers">
								<ul><li>{selectedMembersEnablers}</li></ul>
							</div>
						</div>

						<div style={{ height: "40px" }}></div>
						
					</div>
					
				</div>
			</div>
		)
	} else {
		return (
			<div>

				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="toggle-individual-group">
					<div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
					<Switch
						checked={!toggleSection}
						onChange={toggleIndividualChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
				</div>
	
				<div className="container dashboard">
					<div className="details-container">
	
						{showTitleText(score)}

						<div className="progress-bar">
							<div className="si_ass_result">
								<div className="si_ass_progress">
									<div className="progress-line" style={{ left: score + '%' }}>
										{/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
									</div>
									<span className="msi_score" style={getLeftMargin(score)}>{score}%</span>
								</div>
								{/* <div className="pervalue">
									<h4 className="nimp" style={{ minWidth: '70%' }}></h4>
									<h4 className="mexpe" style={{ minWidth: '10%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '15%' }}></h4>
									<h4 className="vexpe" style={{ minWidth: '5%' }}></h4>
								</div>
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '70%' }}></h4>
									<h4 className="mexpe" style={{ minWidth: '10%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '15%' }}></h4>
								</div> */}
								
								<div className="pervalue">
									<h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
									{/* <h4 className="nimp" style={{ minWidth: '20%' }}></h4> */}
									<h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
									{/* <h4 className="eexpe" style={{ minWidth: '20%' }}></h4> */}
									<h4 className="eexpe1" style={{ minWidth: '30%' }}></h4>
									<h4 className="eexpe2" style={{ minWidth: '20%' }}></h4>
									{/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
								</div>
								
								
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '50%' }}></h4>
									{/* <h4 className="mexpe" style={{ minWidth: '20%' }}></h4> */}
									{/* <h4 className="eexpe" style={{ minWidth: '20%' }}></h4> */}
									<h4 className="eexpe1" style={{ minWidth: '30%' }}></h4>
									{/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
								</div>
								
								<div className="pervalue-text">
									{/* <p className="not-aligned" style={{ marginLeft: '0%', fontWeight: score <= 45 ? 800 : 600 }}>Not<br />Aligned</p> */}
									<p className="poor-aligned" style={{ width: '50%', fontWeight: score > 0 && score <= 50 ? 800 : 600 }}>Needs Attention</p>
									{/* <p className="some-aligned" style={{ marginLeft: '43%', fontWeight: score > 40 && score <= 60 ? 800 : 600 }}>Somewhat<br />Aligned</p> */}
									<p className="well-aligned" style={{ width: '30%', fontWeight: score > 50 && score <= 80 ? 800 : 600 }}>Adequate</p>
									<p className="high-aligned" style={{ width: '20%', fontWeight: score > 80 ? 800 : 600 }}>Excellent</p>
								</div>

							</div>
						</div>
						<div className="spacer"></div>
						<div className="spacer" style={{ height: "15px" }}></div>
	
						{showScoreText(score)}
	
						<div className="result-sections">
	
							{
								sections.map((data, index) => (
								<div key={index} className="score-section">									
									<p className="score-txt">{scores[index]}%</p>
									<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={scores[index]} /></div>
									<p className="score-name">{ data.section }</p>
								</div>)
							)}
	
							{/* <div className="score-section">
								FEEDBACK<br/><br/>
								<div className="score-txt">{subScore2}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore2} /></div>
							</div>
	
							<div className="score-section">
								SERMON<br/><br/>
								<div className="score-txt">{subScore3}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore3} /></div>
							</div>
	
							<div className="score-section">
								COMMUNITY<br/><br/>
								<div className="score-txt">{subScore4}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore4} /></div>
							</div> */}
	
						</div>

						{/* <UrgencyScore {...{ urgencyComponentProps }} /> */}

						<div>
							
							<div className="mobile_scores">
								<h2>INDIVIDUAL SCORES</h2>
								<div className="individual">

								{
									members.map((data, index) => (
										<>
											<div key={index} className="row" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
												<div className="number">{ index + 1 }</div>
												<div className="name">{ data.fullName }</div>
												<div className="percent">{ data.score }%</div>
												<div className="toggle">{ toggleMoreIcon(data.id) }</div>
											</div>
											<div className="scores" style={ toggleMoreDisplay(data.id) }>
												<div className="section_rows">
													<div className="section">Giving</div>
													<div className="section_score">{ data.subScore1 }</div>
												</div>
												<div className="section_rows">
													<div className="section">Messaging</div>
													<div className="section_score">{ data.subScore2 }</div>
												</div>
												<div className="section_rows">
													<div className="section">Metrics</div>
													<div className="section_score">{ data.subScore3 }</div>
												</div>
												<div className="section_rows">
													<div className="section">Leadership</div>
													<div className="section_score">{ data.subScore4 }</div>
												</div>
												<div className="section_rows">
													<div className="section">Team</div>
													<div className="section_score">{ data.subScore5 }</div>
												</div>
											</div>
										</>
									)
								)}

								</div>
								
							</div>

							<table>
								<thead>
									<tr className="header">
										<th className="hash">#</th>
										<th>Name</th>
										{
											sections.map((data, index) => (
												<th key={index} className="center-cell">{ data.section }</th>
												)
												)}
										<th className="avg">Avg (%)</th>
									</tr>
								</thead>
								<tbody>

								{
									members.map((data, index) => (
										<tr key={index}>
											<td>{ index + 1 }</td>
											<td className="medium">{data.fullName}</td>
											{
												sections.map((section, index) => {
													let subScore;
													if(index == 0) { subScore = data.subScore1; }
													else if(index == 1) { subScore = data.subScore2; }
													else if(index == 2) { subScore = data.subScore3; }
													else if(index == 3) { subScore = data.subScore4; }
													else if(index == 4) { subScore = data.subScore5; }
													else if(index == 5) { subScore = data.subScore6; }
													else if(index == 6) { subScore = data.subScore7; }
													else if(index == 7) { subScore = data.subScore8; }
													return <td key={index} className="center-cell">{subScore}</td>
												}
												)}
											<td className="center-cell">{data.score}%</td>
										</tr>
									)
								)}
	
								<tr className='footer'>
									<th className="center-cell">&nbsp;</th>
									<th className="church-average">Team Average</th>
									{
										sections.map((data, index) => (
											<th key={index} className="center-cell">{scores[index]}</th>
											)
											)}
									<th className="center-cell">{score}%</th>
								</tr>
								</tbody>
							</table>
						</div>
					
						<hr/>
	
						<h2>DETAILED REPORT</h2>
										
						{tops?.length > 0 && <div className="most-answered">
							<p className="green">Celebrate what you’re doing well</p>
							{
								tops.map((data, index) => (
									<div key={index} className="most-answered-detail">{index + 1}. { data.question }</div>
								)
							)}
						</div>}
	
						{bottoms?.length > 0 && <div className="most-answered">
							<p className="red">Focus on what needs immediate attention</p>
							{
								bottoms.map((data, index) => (
									<div key={index} className="most-answered-detail">{index + 1}. { data.question }</div>
								)
							)}
						</div>}
	
						<div className="alignment">
							<Tabs members={members} questions={questions} sections={sections} />
						</div>
	
						<div className="hurdles-enablers">
							<h2 style={{ }}>BARRIERS TO GIVING</h2>
							<div className="hurdles">
								<ul>
								{/* {enablers.map((data, index) => (
									data.answer != null ? <li key={index}>{ data.answer }</li> : ""
								))} */}
								{teamHurdles.map((data, index) => (
									data != null ? <li key={index}>{data}</li> : ""
								))}
								</ul>
							</div>
						</div>
	
						<div className="hurdles-enablers">
							<h2 style={{ }}>$1M GIFT INVESTMENT </h2>
							<div className="enablers">
								<ul>
								{/* {hurdles.map((data, index) => (
									data.answer != null ? <li key={index}>{ data.answer }</li> : ""
								))} */}
								{teamEnablers.map((data, index) => (
									data != null ? <li key={index}>{data}</li> : ""
								))}
								</ul>
							</div>
						</div>
	
						<div style={{ height: "80px" }}></div>
	
					</div>
				
				</div>	

				{/* {showSchedule()} */}
			</div>
		);
	}
  

}
export default Dashboard;



import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/testimonial.jpg';

const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">

          {/* <div className="testi">
            <span className="quotation-mark">“</span>The team view is gold! Getting a line of sight into how every member of prospective teams think and being able to see it all on 1 dashboard has helped us a great deal. We’ve now been able to identify trends and personalize follow up for each prospect effectively.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div> */}
          <div className="testi">
            <div className="quotation-mark">“</div>
            <div className="testimonial-text">
            Good assessments, like this one, produce productive conversations and healthy change. The assessment adds more perspectives because it invites feedback from the staff, the board, and the entire senior leadership team.
            </div>
            <div className="quotation-mark bottom">“</div>
          </div>

          <div className="avatar" style={{paddingTop:'1rem'}}>
            <img src={testimonialImage} alt="pastor" />
          </div>

          <div className="designation">
          David Fletcher<br />
            <span>Founder, XPastor.org</span>
          </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;
